<script>
import { to } from "await-to-js";
import { mapState } from "pinia";
import {useEnvStore} from "@dutypay/store-modules";
import uniq from "lodash/uniq";
import each from "lodash/each";
import mime from "mime-types";
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
export default {
  name: "AssetImageList",
  data() {
    return {
      loading: true,
      page: 1,
      totalPages: 1,
      pageSize: 20,
      mediaType: 'image',
      search: "",
      assets: [],
      showUploadForm: false,
      assetToUpload: {},
      columns: [
        {
          prop: "thumbnail",
          label: this.$t("thumbnail.label"),
        },
        {
          prop: "fileName",
          label: this.$t("fileName.label"),
        },
        {
          prop: "imageProps",
          label: this.$t("imageProps.label"),
        },
        {
          prop: "urls",
          label: this.$t("urls.label"),
        },
      ],
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
    filter() {
      return [];
    },
    dayjs(){
      return dayjs;
    }
  },
  methods: {
    printError(err) {
      if (this.debug) {
        this.$dp.log(err);
      }
    },
    async getAssets(page = 1) {
      this.loading = true;
      let err, res;
      [err, res] = await to(
        this.$http.get(`${this.apiUrls.dutypay  }/api/v0/assets/images`, {
          params: {
            page: page,
            pageSize: this.pageSize,
            type: this.mediaType
          },
        })
      );
      if (err) {
        this.printError(err);
        this.loading = false;
        return;
      }
      if(res.data.length > 0){
        this.totalPages = Math.ceil(res.data[0].total / this.pageSize);
      } else {
        this.totalPages = 1;
      }
      this.assets = res.data;
      this.loading = false;
    },
    extensions(item) {
      let mimeTypes = JSON.parse(item.mime_types);
      mimeTypes.push(item.original_mime_type);
      mimeTypes = uniq(mimeTypes);
      let extensions = [];
      each(mimeTypes, (m) => {
        extensions.push(mime.extension(m));
      });
      return extensions;
    },
    async processAssetUpload(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      let err;
      [err] = await to(
        this.$http.post(
          `${this.apiUrls.dutypay}/api/v0/assets/image/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      );
      if (err) {
        this.printError(err);
      }
      await this.getAssets();
    },
    async regenerateAsset(id){
      let err;
      [err] = await to(
        this.$http.post(
          `${this.apiUrls.dutypay}/api/v0/assets/image/regenerate/${id}`
        )
      );
      if (err) {
        this.printError(err);
      }
      await this.getAssets();
    }
  },
  watch: {
    page(newPage) {
      this.getAssets(newPage)
    },
    pageSize() {
      this.getAssets()
    }
  },
  async mounted() {
    await this.getAssets();
  },
};
</script>

<template>
  <div>
    <h1>{{ $t("assetImages.label") }}</h1>
    <div class="dp-flex justify-content__space-between" style="padding-top: 1rem; padding-bottom: 1rem">
      <el-button type="primary" @click="showUploadForm = !showUploadForm">{{
        $t("uploadAsset.buttonLabel")
      }}</el-button>
      <div>
      {{$t('pageSize.label')}}: <el-select v-model="pageSize" style="width: 6em;">
        <el-option
            v-for="item in [5, 10, 20, 50, 100]"
            :key="'pageSize'+item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      </div>
    </div>
    <DpCollapse v-show="!!showUploadForm">
      <div class="partition depth-1">
        <el-upload
          drag
          action=""
          :http-request="processAssetUpload"
          :multiple="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop image here or <em>click to upload</em>
          </div>
        </el-upload>
      </div>
    </DpCollapse>
    <el-row :gutter="20">
      <el-col :xl="{ span: 24 }" :sm="{ span: 24 }" :xs="{ span: 24 }">
        <div style="text-align: center; padding: 1rem;">
          <el-pagination background layout="prev, pager, next" :current-page="page" @update:current-page="page = $event" :page-count="totalPages">
          </el-pagination>
        </div>
        <DpTable
          v-loading="loading"
          :data="assets"
          reference="assets"
          :columns="columns"
          :size="'large'"
          class="assets__dp-table"
          style="width: 100%; max-width: 100%"
        >
          <template v-slot:thumbnail="prop">
            <router-link
              :to="{
                name: 'AssetImageDetails',
                params: { id: prop.row.blob },
              }"
            >
              <el-image
                :src="`${apiUrls.dutypay}/api/v0/assets/image/${prop.row.blob}/webp`"
                style="width: 100px; height: auto; padding: 1rem; background-color: #DEDEDE"
              />
            </router-link>
          </template>
          <template v-slot:fileName="prop">
            <h5><router-link
              :to="{
                name: 'AssetImageDetails',
                params: { id: prop.row.blob },
              }"
              >{{ prop.row.original_filename }}</router-link
            ></h5>

            <DpReadValue :dataKey="$t('hubSystemId.label')" :dataValue="prop.row.blob || '-'"/>
            <DpReadValue :dataKey="$t('spHubsystemImageId.label')" :dataValue="prop.row['sp.hubsystem.image.id'] || '-'"/>
            <DpReadValue :dataKey="$t('uploadTime.label')" :dataValue="dayjs.utc(prop.row.upload_time).local().format('LLL') || '-'"/>

          </template>
          <template v-slot:imageProps="prop">
            <DpReadValue :dataKey="$t('mediaType.label')" :dataValue="prop.row.media_type || '-'"/>
            <DpReadValue :dataKey="$t('imageType.label')" :dataValue="prop.row.image_type || '-'"/>
            <DpReadValue :dataKey="$t('imageObject.label')" :dataValue="prop.row.image_object || '-'"/>
            <DpReadValue :dataKey="$t('imageStyle.label')" :dataValue="prop.row.image_style || '-'"/>
            <DpReadValue :dataKey="$t('applicationOrService.label')" :dataValue="prop.row.application_or_service || '-'"/>
          </template>
          <template v-slot:urls="prop">
            <div>
              <div
                v-for="(ext, index) in extensions(prop.row)"
                :key="index"
                style="display: inline-flex"
              >
                <a
                  :href="`${apiUrls.dutypay}/api/v0/assets/image/${prop.row.blob}/${ext}`"
                  target="_blank"
                  style="border-bottom: 0"
                  ><el-button type="primary" size="small">{{
                    ext.toUpperCase()
                  }}</el-button></a
                >&nbsp;
              </div>
              <div style="margin-top: 1rem">
                <el-button type="warning" size="small" icon="el-icon-refresh" @click="regenerateAsset(prop.row.blob)">{{
                    $t('regenerate.buttonLabel')
                  }}</el-button>
              </div>
            </div>
          </template>
        </DpTable>
        <div style="text-align: center; padding: 1rem;">
          <el-pagination background layout="prev, pager, next" :current-page="page" @update:current-page="page = $event" :page-count="totalPages">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss">
.dp-simple-card__read-item .dp-simple-card__read-key {
  padding-bottom: 0;
}
.dp-simple-card__read-item {
  padding: 0.2em 0;
}
</style>

<i18n>
{
  "ar-AE": {
    "uploadAsset": {
      "buttonLabel": "رفع"
    },
    "pageSize": {
      "label": "إدخالات لكل صفحة"
    },
    "fileName": {
      "label": "اسم الملف"
    }
  },
  "ar-EG": {
    "uploadAsset": {
      "buttonLabel": "رفع"
    },
    "pageSize": {
      "label": "إدخالات لكل صفحة"
    },
    "fileName": {
      "label": "اسم الملف"
    }
  },
  "ar-SA": {
    "uploadAsset": {
      "buttonLabel": "رفع"
    },
    "pageSize": {
      "label": "إدخالات لكل صفحة"
    },
    "fileName": {
      "label": "اسم الملف"
    }
  },
  "de-DE": {
    "uploadAsset": {
      "buttonLabel": "Hochladen"
    },
    "pageSize": {
      "label": "Einträge pro Seite"
    },
    "fileName": {
      "label": "Dateiname"
    }
  },
  "de-AT": {
    "uploadAsset": {
      "buttonLabel": "Hochladen"
    },
    "pageSize": {
      "label": "Einträge pro Seite"
    },
    "fileName": {
      "label": "Dateiname"
    }
  },
  "de-CH": {
    "uploadAsset": {
      "buttonLabel": "Hochladen"
    },
    "pageSize": {
      "label": "Einträge pro Seite"
    },
    "fileName": {
      "label": "Dateiname"
    }
  },
  "en-US": {
    "uploadAsset": {
      "buttonLabel": "Upload"
    },
    "pageSize": {
      "label": "Entries per page"
    },
    "fileName": {
      "label": "File name"
    }
  },
  "en-GB": {
    "uploadAsset": {
      "buttonLabel": "Upload"
    },
    "pageSize": {
      "label": "Entries per page"
    },
    "fileName": {
      "label": "File name"
    }
  },
  "it-IT": {
    "uploadAsset": {
      "buttonLabel": "Caricamento"
    },
    "pageSize": {
      "label": "Voci per pagina"
    },
    "fileName": {
      "label": "Nome del file"
    }
  },
  "fr-FR": {
    "uploadAsset": {
      "buttonLabel": "Téléversement"
    },
    "pageSize": {
      "label": "Entrées par page"
    },
    "fileName": {
      "label": "Nom de fichier"
    }
  },
  "es-ES": {
    "uploadAsset": {
      "buttonLabel": "Subir"
    },
    "pageSize": {
      "label": "Entradas por página"
    },
    "fileName": {
      "label": "Nombre del archivo"
    }
  },
  "pl-PL": {
    "uploadAsset": {
      "buttonLabel": "Wgrywać"
    },
    "pageSize": {
      "label": "Wpisy na stronę"
    },
    "fileName": {
      "label": "Nazwa pliku"
    }
  },
  "nl-NL": {
    "uploadAsset": {
      "buttonLabel": "Uploaden"
    },
    "pageSize": {
      "label": "Inzendingen per pagina"
    },
    "fileName": {
      "label": "Bestandsnaam"
    }
  },
  "bg-BG": {
    "uploadAsset": {
      "buttonLabel": "Качване"
    },
    "pageSize": {
      "label": "Записи на страница"
    },
    "fileName": {
      "label": "Име на файл"
    }
  },
  "bs-BA": {
    "uploadAsset": {
      "buttonLabel": "Upload"
    },
    "pageSize": {
      "label": "Unosi po stranici"
    },
    "fileName": {
      "label": "Ime dokumenta"
    }
  },
  "da-DK": {
    "uploadAsset": {
      "buttonLabel": "Upload"
    },
    "pageSize": {
      "label": "Indlæg pr. side"
    },
    "fileName": {
      "label": "Filnavn"
    }
  },
  "et-EE": {
    "uploadAsset": {
      "buttonLabel": "Laadi üles"
    },
    "pageSize": {
      "label": "Sissekanded lehekülje kohta"
    },
    "fileName": {
      "label": "Faili nimi"
    }
  },
  "fi-FI": {
    "uploadAsset": {
      "buttonLabel": "Lataa"
    },
    "pageSize": {
      "label": "Merkinnät per sivu"
    },
    "fileName": {
      "label": "Tiedoston nimi"
    }
  },
  "el-GR": {
    "uploadAsset": {
      "buttonLabel": "Μεταφόρτωση"
    },
    "pageSize": {
      "label": "Καταχωρήσεις ανά σελίδα"
    },
    "fileName": {
      "label": "Ονομα αρχείου"
    }
  },
  "he-IL": {
    "uploadAsset": {
      "buttonLabel": "העלה"
    },
    "pageSize": {
      "label": "ערכים לכל עמוד"
    },
    "fileName": {
      "label": "שם קובץ"
    }
  },
  "ga-IE": {
    "uploadAsset": {
      "buttonLabel": "Uaslódáil"
    },
    "pageSize": {
      "label": "Iontrálacha in aghaidh an leathanaigh"
    },
    "fileName": {
      "label": "Ainm comhaid"
    }
  },
  "ja-JP": {
    "uploadAsset": {
      "buttonLabel": "アップロード"
    },
    "pageSize": {
      "label": "ページあたりのエントリ"
    },
    "fileName": {
      "label": "ファイル名"
    }
  },
  "hr-HR": {
    "uploadAsset": {
      "buttonLabel": "Učitaj"
    },
    "pageSize": {
      "label": "Unosi po stranici"
    },
    "fileName": {
      "label": "Naziv datoteke"
    }
  },
  "lv-LV": {
    "uploadAsset": {
      "buttonLabel": "Augšupielādēt"
    },
    "pageSize": {
      "label": "Ieraksti katrā lapā"
    },
    "fileName": {
      "label": "Faila nosaukums"
    }
  },
  "lt-LT": {
    "uploadAsset": {
      "buttonLabel": "Įkelti"
    },
    "pageSize": {
      "label": "Įrašai viename puslapyje"
    },
    "fileName": {
      "label": "Failo pavadinimas"
    }
  },
  "pt-PT": {
    "uploadAsset": {
      "buttonLabel": "Carregar"
    },
    "pageSize": {
      "label": "Entradas por página"
    },
    "fileName": {
      "label": "Nome do arquivo"
    }
  },
  "ro-RO": {
    "uploadAsset": {
      "buttonLabel": "Încărcați"
    },
    "pageSize": {
      "label": "Intrări pe pagină"
    },
    "fileName": {
      "label": "Nume de fișier"
    }
  },
  "ru-RU": {
    "uploadAsset": {
      "buttonLabel": "Загрузить"
    },
    "pageSize": {
      "label": "Записей на странице"
    },
    "fileName": {
      "label": "Имя файла"
    }
  },
  "sv-SE": {
    "uploadAsset": {
      "buttonLabel": "Ladda upp"
    },
    "pageSize": {
      "label": "Inlägg per sida"
    },
    "fileName": {
      "label": "Filnamn"
    }
  },
  "sk-SK": {
    "uploadAsset": {
      "buttonLabel": "Nahrať"
    },
    "pageSize": {
      "label": "Záznamy na stránku"
    },
    "fileName": {
      "label": "Názov súboru"
    }
  },
  "cs-CZ": {
    "uploadAsset": {
      "buttonLabel": "Nahrát"
    },
    "pageSize": {
      "label": "Záznamy na stránku"
    },
    "fileName": {
      "label": "Název souboru"
    }
  },
  "tr-TR": {
    "uploadAsset": {
      "buttonLabel": "Yüklemek"
    },
    "pageSize": {
      "label": "Sayfa başına giriş sayısı"
    },
    "fileName": {
      "label": "Dosya adı"
    }
  },
  "hu-HU": {
    "uploadAsset": {
      "buttonLabel": "Feltöltés"
    },
    "pageSize": {
      "label": "Bejegyzések oldalanként"
    },
    "fileName": {
      "label": "Fájl név"
    }
  },
  "fr-BE": {
    "uploadAsset": {
      "buttonLabel": "Téléversement"
    },
    "pageSize": {
      "label": "Entrées par page"
    },
    "fileName": {
      "label": "Nom de fichier"
    }
  },
  "fr-LU": {
    "uploadAsset": {
      "buttonLabel": "Téléversement"
    },
    "pageSize": {
      "label": "Entrées par page"
    },
    "fileName": {
      "label": "Nom de fichier"
    }
  },
  "mt-MT": {
    "uploadAsset": {
      "buttonLabel": "Upload"
    },
    "pageSize": {
      "label": "Entrati għal kull paġna"
    },
    "fileName": {
      "label": "Isem tal-fajl"
    }
  },
  "sl-SI": {
    "uploadAsset": {
      "buttonLabel": "Naloži"
    },
    "pageSize": {
      "label": "Vnosi na stran"
    },
    "fileName": {
      "label": "Ime datoteke"
    }
  },
  "el-CY": {
    "uploadAsset": {
      "buttonLabel": "Μεταφόρτωση"
    },
    "pageSize": {
      "label": "Καταχωρήσεις ανά σελίδα"
    },
    "fileName": {
      "label": "Ονομα αρχείου"
    }
  },
  "tr-CY": {
    "uploadAsset": {
      "buttonLabel": "Yüklemek"
    },
    "pageSize": {
      "label": "Sayfa başına giriş sayısı"
    },
    "fileName": {
      "label": "Dosya adı"
    }
  },
  "uk-UA": {
    "uploadAsset": {
      "buttonLabel": "Завантажити"
    },
    "pageSize": {
      "label": "Записів на сторінці"
    },
    "fileName": {
      "label": "Ім'я файлу"
    }
  },
  "zh-CN": {
    "uploadAsset": {
      "buttonLabel": "上传"
    },
    "pageSize": {
      "label": "每页条目数"
    },
    "fileName": {
      "label": "文件名"
    }
  }
}
</i18n>
